(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/games/assets/javascripts/services/fetch-game.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/games/assets/javascripts/services/fetch-game.js');
"use strict";


const {
  cachedJupiterGet
} = svs.components.marketplaceData;
const {
  normalizeGame
} = svs.components.marketplaceRetailAdminData.games.helpers;
const fetchGame = (groupId, activatedDrawId, callback, optionsBag) => {
  const {
    isCheckingForSettledBet
  } = optionsBag || {};
  const urlFetchGame = "/retailer/1/marketplace/activateddraws/".concat(activatedDrawId);
  const urlFetchSettled = "/marketplace/1/activateddraws/".concat(activatedDrawId, "/settledbet");
  const _sendSuccess = _ref => {
    let {
      activatedDraw: game
    } = _ref;
    return callback(undefined, normalizeGame(game));
  };
  const _sendRedirect = () => callback(undefined, {
    result: 'moved-to-settled'
  });
  const _sendError = error => callback(error);
  fetchGame.cacheRequest(urlFetchGame, _sendSuccess, error => {
    const {
      error: fetchGameError
    } = error.responseJSON;
    if (isCheckingForSettledBet && fetchGameError.code === 404) {
      fetchGame.cacheRequest(urlFetchSettled, _sendRedirect, () => _sendError(fetchGameError));
      return;
    }
    _sendError(fetchGameError);
  });
};
fetchGame.cacheRequest = cachedJupiterGet.createCache(1000);
setGlobal('svs.components.marketplaceRetailAdminData.games.services.fetchGame', fetchGame);

 })(window);