(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/games/assets/javascripts/helpers/wager-row-converter.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/games/assets/javascripts/helpers/wager-row-converter.js');
"use strict";


const {
  splitToRows,
  getExpandedRows,
  splitBombenBoardDataToRows,
  splitBombenExpertenBoardDataToRows
} = svs.components.sport.expandedSystems;
const {
  convertBitmapRowsToReadable
} = svs.components.sport.expandedSystems.utils;
const {
  BitFlag
} = svs.components.lbUtils.bitFlag;
const {
  wagerToRows
} = svs.components.racing.resultUtils;

const rowToIndexes = row => {
  const data = Array(8).fill(0);
  row.forEach(n => {
    data[n] = 1;
  });
  return data;
};
const splitHvsBoardDataToRows = bets => {
  if (bets !== null && bets !== void 0 && bets.scoreBoards) {
    var _bets$scoreBoards;
    return (((_bets$scoreBoards = bets.scoreBoards) === null || _bets$scoreBoards === void 0 || (_bets$scoreBoards = _bets$scoreBoards[0]) === null || _bets$scoreBoards === void 0 ? void 0 : _bets$scoreBoards.boardData) || []).map(row => BitFlag.encode(rowToIndexes(row)));
  }
  if (bets !== null && bets !== void 0 && bets.expertenBoardData) {
    return [];
  }
  return [];
};

function getRows(wager) {
  const firstgame1X2Boards = (wager.bets[0].game1X2Boards || [])[0];
  const expertenBoardData = wager.bets[0].expertenBoardData;
  const scoreBoards = (wager.bets[0].scoreBoards || [])[0];
  const {
    er1X2BoardData
  } = wager.bets[0];

  if (wager.bets[0] && firstgame1X2Boards) {
    const boardSystemType = firstgame1X2Boards.systemType[0];
    if (boardSystemType === 'RSYS') {
      return getExpandedRows(firstgame1X2Boards.systemNum, firstgame1X2Boards.boardData);
    } else if (boardSystemType === 'USYS') {
      return getExpandedRows(firstgame1X2Boards.systemNum, firstgame1X2Boards.boardData, wager.bets[0].game1X2Boards[1].boardData);
    } else if (boardSystemType === 'MSYS') {
      return splitToRows(firstgame1X2Boards.boardData);
    } else if (boardSystemType === 'SROW') {
      return wager.bets[0].game1X2Boards.map(board => board.boardData);
    }
    throw new Error();

  } else if (er1X2BoardData) {
    return convertBitmapRowsToReadable(er1X2BoardData.boards);

  } else if (wager.bets[0].productId === 'ANTMAL') {
    return splitHvsBoardDataToRows(wager.bets[0]);

  } else if (expertenBoardData) {
    return splitBombenExpertenBoardDataToRows(expertenBoardData.eventResults);

  } else if (scoreBoards) {
    return splitBombenBoardDataToRows(scoreBoards.boardData, scoreBoards.rSysData);

  } else if (wager.bets[0].rxBoards) {
    return wagerToRows(wager);
  } else {
    throw new Error();
  }
}
setGlobal('svs.components.marketplaceRetailAdminData.games.helpers.wagerRowConverter', {
  getRows
});

 })(window);