(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/games/assets/javascripts/games-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/games/assets/javascripts/games-actions.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  services
} = svs.components.marketplaceRetailAdminData.games;
const {
  dialogWrapper,
  selectors
} = svs.components.marketplaceData;
const GAMES_HISTORY_FETCH_INIT = 'GAMES/GAMES_HISTORY_FETCH_INIT';
const GAMES_HISTORY_FETCH_SUCCESS = 'GAMES/GAMES_HISTORY_FETCH_SUCCESS';
const GAMES_HISTORY_FETCH_FAILURE = 'GAMES/GAMES_HISTORY_FETCH_FAILURE';
const GAMES_HISTORY_DEFAULT_FETCH_COUNT = 100;
const fetchGamesHistoryAction = function (groupId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (dispatch, getState) => {
    const {
      hasLoadedEverything
    } = getState().GamesHistory[groupId] || {};
    if (hasLoadedEverything) {
      return;
    }
    const gamesHistoryData = selectors.games.getGamesWithStatus(getState(), {
      groupId,
      status: 'finished'
    });
    const offset = typeof options.offset !== 'undefined' ? options.offset : gamesHistoryData.length;
    dispatch({
      type: GAMES_HISTORY_FETCH_INIT,
      groupId
    });
    services.fetchGamesHistory(groupId, offset, GAMES_HISTORY_DEFAULT_FETCH_COUNT, (error, res) => {
      if (error) {
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Något gick tyvärr fel när vi försökte hämta upp avgjorda spel.',
          type: 'error'
        }));
        dispatch({
          type: GAMES_HISTORY_FETCH_FAILURE,
          error,
          groupId
        });
      } else {
        dispatch(_objectSpread({
          type: GAMES_HISTORY_FETCH_SUCCESS,
          groupId
        }, res));
      }
    });
  };
};
const FETCH_SETTLED = 'GAMES/FETCH_SETTLED';
const FETCH_SETTLED_FAILURE = 'GAMES/FETCH_SETTLED_FAILURE';
const FETCH_SETTLED_SUCCESS = 'GAMES/FETCH_SETTLED_SUCCESS';
const fetchSettledBet = (groupId, activatedDrawId) => dispatch => {
  dispatch({
    type: FETCH_SETTLED,
    activatedDrawId,
    groupId
  });
  services.fetchGameHistory(activatedDrawId, (error, game) => {
    if (error) {
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Spelet kunde inte visas.',
        type: 'error'
      }));
      return dispatch({
        type: FETCH_SETTLED_FAILURE,
        error,
        activatedDrawId,
        groupId
      });
    }
    dispatch({
      type: FETCH_SETTLED_SUCCESS,
      game,
      activatedDrawId,
      groupId
    });
  });
};
const FETCH_PLAYER_WAGER = 'GAMES/FETCH_PLAYER_WAGER';
const FETCH_PLAYER_WAGER_FAILURE = 'GAMES/FETCH_PLAYER_WAGER_FAILURE';
const FETCH_PLAYER_WAGER_SUCCESS = 'GAMES/FETCH_PLAYER_WAGER_SUCCESS';
const fetchPlayerWager = wagerSerial => dispatch => {
  dispatch({
    type: FETCH_PLAYER_WAGER,
    wagerSerial
  });
  services.fetchPlayerWager(wagerSerial, (error, wager) => {
    if (error) {
      return dispatch({
        type: FETCH_PLAYER_WAGER_FAILURE,
        error,
        wagerSerial
      });
    }
    dispatch({
      type: FETCH_PLAYER_WAGER_SUCCESS,
      wager
    });
  });
};
const GAMES_FETCH_INIT = 'GAMES/GAMES_FETCH_INIT';
const GAMES_FETCH_SUCCESS = 'GAMES/GAMES_FETCH_SUCCESS';
const GAMES_FETCH_FAILURE = 'GAMES/GAMES_FETCH_FAILURE';
const fetchGamesAction = groupId => (dispatch, getState) => {
  dispatch({
    type: GAMES_FETCH_INIT,
    groupId
  });
  services.fetchGames(getState().GamesExcludeFetch || [], (error, games) => {
    if (error) {
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Vi kunde inte hämta era spel, därför kan information vara missvisande.',
        type: 'error'
      }));
      dispatch({
        type: GAMES_FETCH_FAILURE,
        error,
        groupId
      });
    } else {
      dispatch({
        type: GAMES_FETCH_SUCCESS,
        games,
        groupId
      });
    }
  });
};
const GAME_FETCH_INIT = 'GAMES/GAME_FETCH_INIT';
const GAME_FETCH_FAILURE = 'GAMES/GAME_FETCH_FAILURE';
const GAME_FETCH_MOVED_TO_SETTLED = 'GAMES/GAME_FETCH_MOVED_TO_SETTLED';
const GAME_FETCH_SUCCESS = 'GAMES/GAME_FETCH_SUCCESS';
const fetchGameAction = (groupId, activatedDrawId, optionsBag) => dispatch => {
  const {
    isCheckingForSettledBet
  } = optionsBag || {};
  dispatch({
    type: GAME_FETCH_INIT,
    activatedDrawId,
    groupId
  });
  const callback = (error, game) => {
    if (error) {
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Spelet kunde inte visas.',
        type: 'error'
      }));
      dispatch({
        type: GAME_FETCH_FAILURE,
        error,
        activatedDrawId,
        groupId
      });
      return;
    }
    if (isCheckingForSettledBet && game && game.result === 'moved-to-settled') {
      dispatch({
        type: GAME_FETCH_MOVED_TO_SETTLED,
        activatedDrawId,
        groupId
      });
      return;
    }
    dispatch({
      type: GAME_FETCH_SUCCESS,
      game,
      activatedDrawId,
      groupId
    });
  };
  services.fetchGame(groupId, activatedDrawId, callback, {
    isCheckingForSettledBet
  });
};
const REMOVE_GAME_INIT = 'GAMES/REMOVE_GAME_INIT';
const REMOVE_GAME_FAILED = 'GAMES/REMOVE_GAME_FAILED';
const REMOVE_GAME_SUCCESS = 'GAMES/REMOVE_GAME_SUCCESS';
const removeGameAction = (groupId, activatedDrawId) => dispatch => {
  dispatch({
    type: REMOVE_GAME_INIT,
    groupId,
    activatedDrawId
  });
  services.removeGame(activatedDrawId, error => {
    if (error) {
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Spelomgången kunde inte tas bort',
        type: 'error'
      }));
      return dispatch({
        type: REMOVE_GAME_FAILED,
        groupId,
        activatedDrawId,
        error
      });
    }
    dispatch({
      type: REMOVE_GAME_SUCCESS,
      groupId,
      activatedDrawId
    });
  });
};
setGlobal('svs.components.marketplaceRetailAdminData.games.actions', {
  GAMES_HISTORY_FETCH_INIT,
  GAMES_HISTORY_FETCH_SUCCESS,
  GAMES_HISTORY_FETCH_FAILURE,
  fetchGamesHistoryAction,
  FETCH_SETTLED,
  FETCH_SETTLED_FAILURE,
  FETCH_SETTLED_SUCCESS,
  fetchSettledBet,
  FETCH_PLAYER_WAGER,
  FETCH_PLAYER_WAGER_FAILURE,
  FETCH_PLAYER_WAGER_SUCCESS,
  fetchPlayerWager,
  GAMES_FETCH_INIT,
  GAMES_FETCH_SUCCESS,
  GAMES_FETCH_FAILURE,
  fetchGamesAction,
  GAME_FETCH_INIT,
  GAME_FETCH_SUCCESS,
  GAME_FETCH_MOVED_TO_SETTLED,
  GAME_FETCH_FAILURE,
  fetchGameAction,
  REMOVE_GAME_INIT,
  REMOVE_GAME_FAILED,
  REMOVE_GAME_SUCCESS,
  removeGameAction
});

 })(window);