(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/games/assets/javascripts/services/fetch-game-history.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/games/assets/javascripts/services/fetch-game-history.js');
"use strict";


const {
  helpers
} = svs.components.marketplaceRetailAdminData.games;
const {
  createCache
} = svs.components.marketplaceData.cachedJupiterGet;
const fetchGameHistory = (activatedDrawId, callback) => {
  fetchGameHistory.cachedRequest("/marketplace/1/activateddraws/".concat(activatedDrawId, "/settledbet"), response => callback(undefined, helpers.normalizeGame(response.history)), error => {
    callback(error.responseJSON.error);
  });
};
fetchGameHistory.cachedRequest = createCache(1000);
setGlobal('svs.components.marketplaceRetailAdminData.games.services.fetchGameHistory', fetchGameHistory);

 })(window);