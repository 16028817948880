(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/games/assets/javascripts/helpers/draw-state-constants.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/games/assets/javascripts/helpers/draw-state-constants.js');
"use strict";

const drawStates = {
  PREPARED: 1,
  OPEN: 2,
  BUYINCLOSED: 3,
  CLOSED: 4,
  REFUNDING: 5,
  FINALIZED: 20
};
setGlobal('svs.components.marketplaceRetailAdminData.games.helpers.drawStates', drawStates);

 })(window);