(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/games/assets/javascripts/services/fetch-player-wager.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/games/assets/javascripts/services/fetch-player-wager.js');
"use strict";


const {
  jupiter
} = svs.core;
const fetchPlayerWager = (wagerSerial, callback) => {
  jupiter.get("/player/1/wagers/".concat(wagerSerial), response => {
    callback(null, response.wager);
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceRetailAdminData.games.services.fetchPlayerWager', fetchPlayerWager);

 })(window);