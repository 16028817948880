(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/games/assets/javascripts/services/fetch-games.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/games/assets/javascripts/services/fetch-games.js');
"use strict";


const {
  cachedJupiterGet
} = svs.components.marketplaceData;
const {
  normalizeGame
} = svs.components.marketplaceRetailAdminData.games.helpers;
const {
  normalizeRaceMarketplace
} = svs.components.marketplaceData.draws.helpers;
const {
  gameTypeToSubProductId,
  gameTypes
} = svs.racing.common.constants;
const TOPP5 = gameTypeToSubProductId[gameTypes.TOPP5];
const fetchGames = (excludedIds, callback) => {
  const urls = ["/retailer/1/marketplace/activateddraws", "/racing/1/racecard/product/".concat(TOPP5, "?includeDefined=true")];
  fetchGames.cacheRequest(urls, response => {
    let gamesResponse;
    let raceDrawsResponse;
    try {
      gamesResponse = response.responses[0];
      raceDrawsResponse = response.responses[1];
    } catch (err) {
      callback({
        message: err
      });
      return;
    }
    if (!gamesResponse.activatedDraws) {
      callback({
        message: 'missing `activatedDrawId` on response'
      });
      return;
    }
    if (!raceDrawsResponse.raceCard) {
      callback({
        message: 'missing `raceCard` on response'
      });
      return;
    }
    const normalizedDrawResponse = normalizeRaceMarketplace(raceDrawsResponse);
    const drawsByDrawNumber = {};
    for (const draw of normalizedDrawResponse.draws) {
      drawsByDrawNumber[draw.drawNumber] = draw;
    }
    const normalizedActivatedDraws = gamesResponse.activatedDraws.map(game => normalizeGame(game, drawsByDrawNumber[game.drawNumber]));
    const filteredGames = normalizedActivatedDraws.filter(game => excludedIds.indexOf(game.activatedDrawId) === -1);
    callback(undefined, filteredGames);
  }, error => {
    callback(error.responseJSON.error);
  });
};
fetchGames.cacheRequest = cachedJupiterGet.createCache(1000);
setGlobal('svs.components.marketplaceRetailAdminData.games.services.fetchGames', fetchGames);

 })(window);