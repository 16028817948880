(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/games/assets/javascripts/helpers/settled-bet-statuses.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/games/assets/javascripts/helpers/settled-bet-statuses.js');
var settledBetStatuses = {
  CANCELLED: 1,
  SETTLED: 2
};

setGlobal(
  'svs.components.marketplaceRetailAdminData.games.helpers.settledBetStatuses',
  settledBetStatuses
);


 })(window);