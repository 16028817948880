(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/games/assets/javascripts/helpers/normalize-game.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/games/assets/javascripts/helpers/normalize-game.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  GAME_STATUS_PREPARED,
  GAME_STATUS_FINISHED,
  GAME_STATUS_OPEN,
  GAME_STATUS_DRAW_CLOSED,
  GAME_BUYIN_CLOSED,
  GAME_STATUS_REFUNDED,
  GAME_STATUS_SUBMITTABLE,
  GAME_STATUS_SUBMITTED,
  GAME_STATUS_CANCELLED,
  GAME_STATUS_SUBMITTABLE_NOT_OPEN
} = svs.components.marketplaceData.gamesConstants;
const {
  drawStates,
  settledBetStatuses
} = svs.components.marketplaceRetailAdminData.games.helpers;
const {
  parseEditorState
} = svs.components.marketplaceRetailAdmin.richText.helpers;
const {
  DRAWSTATE_DEFINED
} = svs.components.marketplaceData.constants;
const getGameStatus = (_ref, draw) => {
  let {
    activatedDrawStatus,
    completedDateTime,
    regCloseTime,
    wagerSerial,
    isSubmittable,
    settledBetStatus
  } = _ref;
  const isFinished = Boolean(completedDateTime);
  const isDrawDefined = draw && draw.drawStateId === DRAWSTATE_DEFINED;
  switch (true) {
    case settledBetStatus === settledBetStatuses.CANCELLED:
      return GAME_STATUS_CANCELLED;
    case isFinished || activatedDrawStatus === drawStates.FINALIZED:
      return GAME_STATUS_FINISHED;
    case wagerSerial && !isFinished:
      return GAME_STATUS_SUBMITTED;
    case new Date() > new Date(regCloseTime):
      return GAME_STATUS_DRAW_CLOSED;
    case isSubmittable && !wagerSerial && isDrawDefined:
      return GAME_STATUS_SUBMITTABLE_NOT_OPEN;
    case isSubmittable && !wagerSerial:
      return GAME_STATUS_SUBMITTABLE;
    case activatedDrawStatus === drawStates.PREPARED:
      return GAME_STATUS_PREPARED;
    case activatedDrawStatus === drawStates.BUYINCLOSED:
      return GAME_BUYIN_CLOSED;
    case activatedDrawStatus === drawStates.OPEN:
      return GAME_STATUS_OPEN;
    case activatedDrawStatus === drawStates.REFUNDING:
      return GAME_STATUS_REFUNDED;
    default:
      return 'Okänd status';
  }
};
const normalizeGame = (game, draw) => {
  const {
    getRows
  } = svs.components.marketplaceRetailAdminData.games.helpers.wagerRowConverter;
  const {
    editorName,
    soldShares,
    maxShares,
    containerId,
    bettorName,
    isSubmittable
  } = game;
  const realIsSubmittable = isSubmittable && soldShares >= 2; 

  const wager = game.wager;
  const bets = wager ? Array.isArray(wager.bets) && wager.bets.filter(bet => bet.drawNum !== 0) : {};
  const rows = wager && bets ? getRows(wager) : [];
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, game), {}, {
    ownerName: editorName || bettorName,
    soldFractionsCount: soldShares,
    maxFractions: maxShares,
    templateId: containerId,
    status: getGameStatus(_objectSpread(_objectSpread({}, game), {}, {
      isSubmittable: realIsSubmittable
    }), draw)
  }, game.buyinCloseTime ? {
    buyStop: new Date(game.buyinCloseTime)
  } : {}), game.regCloseTime ? {
    drawCloseTime: new Date(game.regCloseTime)
  } : {}), game.activatedDrawDescription || game.description ? {
    richDescription: parseEditorState(game.activatedDrawDescription || game.description)
  } : {
    richDescription: Draft.EditorState.createEmpty()
  }), wager && Object.keys(wager).length ? {
    wager: _objectSpread(_objectSpread({}, wager), {}, {
      bets,
      rows
    })
  } : {
    wager: null
  }), {}, {
    isSubmittable: realIsSubmittable
  });
};
setGlobal('svs.components.marketplaceRetailAdminData.games.helpers.normalizeGame', normalizeGame);

 })(window);