(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/games/assets/javascripts/services/fetch-games-history.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/games/assets/javascripts/services/fetch-games-history.js');
"use strict";


const {
  cachedJupiterGet
} = svs.components.marketplaceData;
const {
  normalizeGame
} = svs.components.marketplaceRetailAdminData.games.helpers;
const fetchGamesHistory = (groupId, offset, count, callback) => {
  fetchGamesHistory.cacheRequest("/marketplace/1/groups/".concat(groupId, "/history/settledbets?offset=").concat(offset, "&count=").concat(count), response => {
    callback(undefined, {
      games: (response.settledBets || []).map(normalizeGame),
      hasLoadedEverything: !response.moreAvailable
    });
  }, error => {
    callback(error.responseJSON.error);
  });
};
fetchGamesHistory.cacheRequest = cachedJupiterGet.createCache(1000);
setGlobal('svs.components.marketplaceRetailAdminData.games.services.fetchGamesHistory', fetchGamesHistory);

 })(window);